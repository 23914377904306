import {
  createContext,
  useContext,
  useReducer,
  useCallback,
  useEffect,
  useState,
} from 'react';
import take from 'lodash/take';
import uniqBy from 'lodash/uniqBy';

// Constants
const STATE_EXPIRATION_TIME = 3600000; //1 hour in milliseconds

// Create and export App's Context
export const AppContext = createContext();

// Use this function inside children to get context
export const useAppContext = () => useContext(AppContext);

// Initial state
const initialState = {
  user: {
    logged: false,
    showLoginDialog: false,
    reductionsIDs: [],
    productsReminder: [],
  },
  cart: {
    timer: null,
    end: null,
    products: [],
    reconciliation: false,
    showCountDown: true,
  },
};

// Custom hook that manages context state
export const useAppContextState = () => {
  const reducer = (state, action) => {
    switch (action.type) {
      // User reducers
      case 'setLogin':
        return {
          ...state,
          user: {
            ...state.user,
            logged: true,
            ...action.payload.user,
            access_token: action.payload.access_token,
            expires_in: action.payload.expires_in,
          },
        };

      case 'setLogout':
        return {
          ...state,
          user: {
            ...initialState.user,
            productsReminder: state.user.productsReminder,
          },
        };

      case 'setUser':
        return {
          ...state,
          user: {
            ...state.user,
            ...action.payload,
          },
        };

      case 'onShowLoginDialog':
        return {
          ...state,
          user: {
            ...state.user,
            showLoginDialog: action.payload,
          },
        };

      case 'setReductionsIDs':
        return state.user.logged
          ? {
              ...state,
              user: {
                ...state.user,
                reductionsIDs: action.payload,
              },
            }
          : state;

      case 'setProductsReminder':
        const oldOnes =
          state.user.productsReminder.length > 0
            ? state.user.productsReminder
            : [];
        return {
          ...state,
          user: {
            ...state.user,
            productsReminder: take(
              uniqBy([action.payload, ...oldOnes], 'slug'),
              3,
            ),
          },
        };

      case 'resetProductsReminder':
        return {
          ...state,
          user: {
            ...state.user,
            productsReminder: [],
          },
        };

      // Cart reducers
      case 'updateCartItem':
        return {
          ...state,
          cart: {
            ...state.cart,
            products: state.cart.products.map((item) =>
              item?.id === action.payload?.id &&
              item.date === action.payload?.date
                ? { ...item, qty: action.payload?.qty }
                : item,
            ),
          },
        };

      case 'setCart':
        return {
          ...state,
          cart: {
            ...state.cart,
            products: [
              ...(state.cart?.products || []),
              ...action.payload,
            ],
          },
        };

      case 'removeCartItem':
        return {
          ...state,
          cart: {
            ...state.cart,
            products: state.cart.products.filter(
              (p) => p.id !== action.payload,
            ),
          },
        };

      case 'setCartTimer':
        return {
          ...state,
          cart: {
            ...state.cart,
            timer: action.payload,
          },
        };

      case 'setCartDateEnd':
        return {
          ...state,
          cart: {
            ...state.cart,
            end: action.payload,
          },
        };

      case 'deleteCart':
        return {
          ...state,
          cart: {
            ...initialState.cart,
          },
        };

      case 'removeCartItems':
        return {
          ...state,
          cart: {
            ...state.cart,
            products: state.cart.products.filter(
              (item) => !action.payload?.ids?.includes(item?.id),
            ),
          },
        };

      case 'setShowCountDown':
        return {
          ...state,
          cart: {
            ...state.cart,
            showCountDown: action.payload,
          },
        };

      case 'setReconciliation':
        return {
          ...state,
          cart: {
            ...state.cart,
            reconciliation: action.payload,
          },
        };

      case 'init':
        return action.payload;

      default:
        return state;
    }
  };

  const [isClient, setIsClient] = useState(false);
  const [state, dispatch] = useReducer(reducer, initialState);

  // Load state from localStorage on client side
  useEffect(() => {
    setIsClient(true);
    try {
      const serializedState = localStorage.getItem('appState');
      const expirationTime = localStorage.getItem(
        'appStateExpiration',
      );
      if (serializedState !== null && expirationTime !== null) {
        const currentTime = new Date().getTime();
        if (currentTime <= expirationTime) {
          dispatch({
            type: 'init',
            payload: JSON.parse(serializedState),
          });
        } else {
          localStorage.removeItem('appState');
          localStorage.removeItem('appStateExpiration');
        }
      }
    } catch (err) {
      // Handle errors here
    }
  }, []);

  // Save state to localStorage whenever it changes
  useEffect(() => {
    if (isClient) {
      try {
        const serializedState = JSON.stringify(state);
        const expirationTime =
          new Date().getTime() + STATE_EXPIRATION_TIME;
        localStorage.setItem('appState', serializedState);
        localStorage.setItem('appStateExpiration', expirationTime);
      } catch (err) {
        // Handle errors here
      }
    }
  }, [state, isClient]);

  // Memoized User actions
  const setLogin = useCallback((payload) => {
    const { user, access_token, expires_in } = payload;
    dispatch({
      type: 'setLogin',
      payload: { user, access_token, expires_in },
    });
  }, []);

  const setLogout = useCallback(() => {
    dispatch({ type: 'setLogout' });
  }, []);

  const setUser = useCallback((payload) => {
    dispatch({
      type: 'setUser',
      payload,
    });
  }, []);

  const onShowLoginDialog = useCallback((value) => {
    dispatch({
      type: 'onShowLoginDialog',
      payload: value,
    });
  }, []);

  const setReductionsIDs = useCallback((ids) => {
    dispatch({
      type: 'setReductionsIDs',
      payload: ids,
    });
  }, []);

  const setProductsReminder = useCallback((product) => {
    dispatch({
      type: 'setProductsReminder',
      payload: product,
    });
  }, []);

  const resetProductsReminder = useCallback(() => {
    dispatch({ type: 'resetProductsReminder' });
  }, []);

  // Memoized Cart actions
  const updateCartItem = useCallback((item) => {
    dispatch({
      type: 'updateCartItem',
      payload: item,
    });
  }, []);

  const setCart = useCallback((products) => {
    dispatch({
      type: 'setCart',
      payload: products,
    });
  }, []);

  const removeCartItem = useCallback((id) => {
    dispatch({
      type: 'removeCartItem',
      payload: id,
    });
  }, []);

  const setCartTimer = useCallback((timer) => {
    dispatch({
      type: 'setCartTimer',
      payload: timer,
    });
  }, []);

  const setCartDateEnd = useCallback((endDate) => {
    dispatch({
      type: 'setCartDateEnd',
      payload: endDate,
    });
  }, []);

  const deleteCart = useCallback(() => {
    dispatch({ type: 'deleteCart' });
  }, []);

  const removeCartItems = useCallback((item) => {
    dispatch({
      type: 'removeCartItems',
      payload: item,
    });
  }, []);

  const setShowCountDown = useCallback((value) => {
    dispatch({
      type: 'setShowCountDown',
      payload: value,
    });
  }, []);

  const setReconciliation = useCallback((value) => {
    dispatch({
      type: 'setReconciliation',
      payload: value,
    });
  }, []);

  return {
    ...state,
    setLogin,
    setLogout,
    setUser,
    onShowLoginDialog,
    setReductionsIDs,
    setProductsReminder,
    resetProductsReminder,
    updateCartItem,
    setCart,
    removeCartItem,
    setCartTimer,
    setCartDateEnd,
    deleteCart,
    removeCartItems,
    setShowCountDown,
    setReconciliation,
  };
};
