import { itIT } from '@mui/material/locale';
import { orange } from '@mui/material/colors';

const fonts = {
  primary: 'Fira Sans Condensed, sans-serif',
  secondary: 'Playfair Display, serif',
};

const MUI_THEME = {
  itIT,
  shadows: Array(25).fill('none'),
  spacing: 20,
  tonalOffset: 0.2,
  contrastThreshold: 10,
  shape: { borderRadius: 5 },

  props: {
    MuiAccordionSummary: {
      disableElevation: true,
    },
  },
  palette: {
    type: 'dark',
    primary: {
      main: '#da5f06',
    },
    secondary: {
      main: '#c2d1d9',
    },
    background: {
      default: 'var(--default-bg-color)',
      paper: '#212121',
    },
    text: {
      primary: '#ffffff',
      secondary: '#ffffff',
      disabled: '#ffffff',
      hint: '#ffffff',
    },
    divider: '#ffffff',
  },
  typography: {
    fontFamily: fonts.primary,
    subtitle1: {
      fontFamily: fonts.primary,
    },
    subtitle2: {
      fontFamily: fonts.primary,
    },
    body1: {
      fontFamily: fonts.primary,
    },
    body2: {
      fontFamily: fonts.primary,
    },
    button: {
      fontFamily: fonts.primary,
    },
    caption: {
      fontFamily: fonts.primary,
    },
    overline: {
      fontFamily: fonts.primary,
    },
    h1: {
      fontFamily: fonts.secondary,
    },
    h2: {
      fontFamily: fonts.secondary,
    },
    h3: {
      fontFamily: fonts.secondary,
    },
    h4: {
      fontFamily: fonts.secondary,
    },
    h5: {
      fontFamily: fonts.secondary,
    },
    h6: {
      fontFamily: fonts.secondary,
    },
  },
  components: {
    MuiTreeItem: {
      styleOverrides: {
        root: {
          //backgroundColor: 'red',
          '.Mui-selected': {
            background: 'transparent !important;',
          },
        },
      },
    },

    MuiAccordion: {
      styleOverrides: {
        root: {
          '&:before': {
            display: 'none',
          },
          backgroundColor: 'transparent',
        },
      },
    },

    MuiTab: {
      /* styleOverrides: {
        root: {
          '&:before': {
            display: 'none',
          },
          '&.Mui-selected': {
            color: '#faaf40',
            '&:before': {
              display: 'none',
            },
          },
        },
      }, */
    },

    MuiFab: {
      styleOverrides: {
        backgroundColor: orange[700],
        root: {
          '& .MuiTabs-scroller': {
            '& .MuiTabs-indicator': {
              height: 0,
              borderRadius: '20px 20px 0 0',
              backgroundColor: orange[700],
            },
          },
        },
      },
    },

    /*  MuiTabs: {
      indicator: {
        backgroundColor: orange[700],
      },
      styleOverrides: {
        root: {
          '& .MuiTabs-scroller': {
            '& .MuiTabs-indicator': {
              height: 0,
              borderRadius: '20px 20px 0 0',
              backgroundColor: orange[700],
            },
          },
        },
      },
    }, */

    /* MuiButtonBase: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {},
        },
      },
      defaultProps: {
        disableRipple: true,
        size: 'small',
      },
    }, */

    MuiPickerStaticWrapper: {
      styleOverrides: {
        root: {
          //fontSize: '1.9rem',
          minWidth: '100%',
          backgroundColor: 'transparent',
          button: {
            backgroundColor: 'current',
          },
        },
      },
    },

    PrivatePickersFadeTransitionGroup: {
      styleOverrides: {
        root: {
          overflowY: 'hidden !important;',
        },
      },
    },

    MuiPickersDay: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          backgroundColor: 'transparent',
          //fontWeight: 700
        },
      },
    },

    PrivatePickersSlideTransition: {
      styleOverrides: {
        root: {
          /*  'div[role="row"]': {
            justifyContent: "space-between"
          }, */
        },
      },
    },

    MuiCalendarPicker: {
      styleOverrides: {
        root: {
          width: '100%',
          maxHeight: '100%',
          backgroundColor: 'transparent',
        },
      },
      defaultProps: {
        disableRipple: true,
        size: 'small',
      },
      daySelected: {
        backgroundColor: 'transparent',
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          input: {
            '&:-webkit-autofill': {
              WebkitBoxShadow: 'none',
            },
          },
        },
      },
    },
  },
  overrides: {
    MuiOutlinedInput: {
      input: {
        '&:-webkit-autofill': {
          '-webkit-box-shadow': 'none',
          '-webkit-text-fill-color': '#fff',
        },
      },
    },

    MuiButton: {
      color: '#000',
      backgroundColor: '#000',
      '&:hover': {
        backgroundColor: '#000',
      },
    },

    MuiTreeItem: {
      '.Mui-selected': {
        backgroundColor: 'trasparent !important;',
      },
    },
  },
};

export default MUI_THEME;
