import '@styles/global.scss';
import '@splidejs/react-splide/css';

import {
  AppContext,
  CustomThemeProvider,
  useAppContextState,
} from '@containers';
import Script from 'next/script';
import { appWithTranslation } from 'next-i18next';

function App({ Component, pageProps, router }) {
  //? initialize context
  const appContextState = useAppContextState();

  return (
    <>
      <AppContext.Provider value={appContextState}>
        <Script
          id="gtag"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}

            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                      })(window,document,'script','dataLayer','GTM-NVFMZR8');`,
          }}
        />

        <CustomThemeProvider>
          <Component {...pageProps} key={router.asPath} />
        </CustomThemeProvider>
      </AppContext.Provider>
    </>
  );
}

export default appWithTranslation(App /*, nextI18NextConfig */);
