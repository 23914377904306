import { ThemeProvider, createTheme } from '@mui/material/styles';

import CssBaseline from '@mui/material/CssBaseline';
import MUI_THEME from './themes/mui.theme';

export const CustomThemeProvider = ({ children }) => {
  return (
    <ThemeProvider
      theme={createTheme({
        breakpoints: {
          values: {
            xs: 0,
            sm: 640,
            md: 768,
            lg: 1024,
            xl: 1280,
          },
        },
        ...MUI_THEME,
      })}
    >
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};
